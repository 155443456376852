import React, { useEffect } from "react";
import { Footer, Navbar } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { addCart, delCart } from "../redux/action";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Cart = () => {
  const state = useSelector((state) => state.handleCart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("auth"))?.token;
  const handleOnCheckout = async () => {
    if (token) {
      axios
        .post(`${BASE_URL}/verifyUser`, {
          token: token,
        })
        .then(
          (res) => {
            navigate("/checkout");
          },
          (error) => {
            alert("You must be logged in to access this page");
            navigate("/login");
            localStorage.removeItem("auth");
          }
        );
    } else {
      alert("You must be logged in to access this page");
      navigate("/login");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const EmptyCart = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-5 bg-light text-center">
            <h4 className="p-3 display-5">Your Cart is Empty</h4>
            <Link
              to="/"
              className="btn bg-lime-500 text-white px-4 py-2 rounded-md transition hover:bg-lime-600"
            >
              <i className="fa fa-arrow-left mr-2"></i> Continue Shopping
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const addItem = (product) => {
    dispatch(addCart(product));
  };
  const removeItem = (product) => {
    dispatch(delCart(product));
  };

  const ShowCart = () => {
    let subtotal = 0;
    let shipping = 30.0;
    let totalItems = 0;
    state.map((item) => {
      return (subtotal += item.price * item.qty);
    });

    state.map((item) => {
      return (totalItems += item.qty);
    });

    return (
      <section className="h-100 gradient-custom">
        <div className="container py-5">
          <div className="row d-flex justify-content-center my-4">
            <div className="col-md-8">
              <div className="card mb-4 shadow-sm rounded-lg">
                <div className="card-header py-3 bg-gray-100">
                  <h5 className="mb-0 text-xl font-bold">Item List</h5>
                </div>
                <div className="card-body">
                  {state.map((item) => {
                    return (
                      <div key={item.id}>
                        <div className="row d-flex align-items-center">
                          <div className="col-lg-3 col-md-12">
                            <img
                              src={item.image}
                              alt={item.title}
                              className="rounded shadow-sm w-100"
                              style={{ maxWidth: "100px", height: "75px" }}
                            />
                          </div>
                          <div className="col-lg-5 col-md-6">
                            <p className="font-semibold">{item.title}</p>
                          </div>
                          <div className="col-lg-4 col-md-6 flex items-center justify-between">
                            <button
                              className="btn px-3 bg-gray-200 hover:bg-gray-300 transition rounded-md"
                              onClick={() => removeItem(item)}
                            >
                              <i className="fas fa-minus"></i>
                            </button>
                            <p className="mx-4">{item.qty}</p>
                            <button
                              className="btn px-3 bg-lime-500 text-white rounded-md hover:bg-lime-600 transition"
                              onClick={() => addItem(item)}
                            >
                              <i className="fas fa-plus"></i>
                            </button>
                            <p className="text-lg font-semibold ml-4">
                              ₹{item.price}
                            </p>
                          </div>
                        </div>
                        <hr className="my-4" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4 shadow-sm rounded-lg">
                <div className="card-header py-3 bg-gray-100">
                  <h5 className="mb-0 text-xl font-bold">Order Summary</h5>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                      Products ({totalItems})
                      <span>₹{Math.round(subtotal)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                      Shipping
                      <span>₹{shipping}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                      <strong>Total amount</strong>
                      <strong>₹{Math.round(subtotal + shipping)}</strong>
                    </li>
                  </ul>
                  <button
                    onClick={handleOnCheckout}
                    className="btn bg-lime-500 text-white w-full py-3 rounded-md transition hover:bg-lime-600"
                  >
                    Go to checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center text-3xl font-bold mb-4">Cart</h1>
        <hr />
        {state.length > 0 ? <ShowCart /> : <EmptyCart />}
      </div>
      <Footer />
    </>
  );
};

export default Cart;
