import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Navbar />
      <div className="container mx-auto p-6">
        <h1 className="text-4xl font-bold mb-4 text-lime-600">
          Privacy Policy
        </h1>
        <p className="mb-6 text-gray-700">
          At Prop The Crop, your privacy is of utmost importance to us. This
          Privacy Policy outlines how we collect, use, and protect your personal
          information when you use our website or purchase our products.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">
          Information We Collect
        </h2>
        <p className="mb-6 text-gray-700">
          We may collect information such as your name, email address, phone
          number, and shipping address when you place an order. This information
          is used solely to fulfill your orders and provide you with updates.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">
          How We Use Your Information
        </h2>
        <p className="mb-6 text-gray-700">
          Your information is used to process orders, manage your account, and
          improve your experience on our site. We do not sell or share your
          personal information with third parties except as necessary to fulfill
          your orders.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">
          Security of Your Information
        </h2>
        <p className="mb-6 text-gray-700">
          We take appropriate security measures to protect your personal
          information from unauthorized access, disclosure, or alteration.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">
          Your Consent
        </h2>
        <p className="mb-6 text-gray-700">
          By using our website, you consent to our Privacy Policy. If we update
          our policy, we will post the changes on this page.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">
          Contact Us
        </h2>
        <p className="text-gray-700">
          If you have any questions about our Privacy Policy, please contact us
          at{" "}
          <span className="text-lime-600 font-semibold">
            support@propthecrop.com
          </span>
          .
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
