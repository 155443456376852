import React from "react";
import ReactDOM from "react-dom/client";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./components/css/index.css";

import {
  Home,
  Product,
  Products,
  AboutPage,
  ContactPage,
  Cart,
  Login,
  Register,
  Checkout,
  PageNotFound,
} from "./pages";
import Dashboard from "./pages/userDashboard/Dashboard";
import UserOrders from "./pages/userDashboard/UserOrders";
import UserAddress from "./pages/userDashboard/UserAddress";
import UserCredentials from "./pages/userDashboard/UserCredentials";
import PaymentGateway from "./pages/PaymentGateway";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ReturnPolicy from "./components/ReturnPolicy";
import TermsAndConditions from "./components/TermsAndConditions";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Products />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/product/*" element={<PageNotFound />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/return-policy" element={<ReturnPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/payment-gateway" element={<PaymentGateway />} />
        <Route path="/user-dashboard" element={<Dashboard />} />
        <Route path="/user-dashboard/orders" element={<UserOrders />} />
        <Route path="/user-dashboard/address" element={<UserAddress />} />
        <Route
          path="/user-dashboard/credentials"
          element={<UserCredentials />}
        />
      </Routes>
    </Provider>
  </BrowserRouter>
);
