import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action";
import axios from "axios";
import { Footer, Navbar } from "../components";
import toast, { Toaster } from "react-hot-toast";

const Product = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const addProduct = (product) => {
    dispatch(addCart(product));
    localStorage.setItem("cartItems", JSON.stringify(product));
    toast.success("Added to Cart");
  };

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      setLoading2(true);
      try {
        const response = await axios.get(`${BASE_URL}/api/products/${id}`);
        const data = response.data;
        setProduct(data);
        setLoading(false);
        const response2 = await axios.get(
          `${BASE_URL}/api/category?type=${data.category}`
        );
        //console.log(response2.data);
        setSimilarProducts(response2.data != null ? response2.data : []);
        setLoading2(false);
      } catch (error) {
        //console.error("Failed to fetch product", error);
        setError("Failed to load product data. Please try again later.");
        setLoading(false);
        setLoading2(false);
      }
    };
    fetchProduct();
    window.scrollTo(0, 0);
  }, [id]);

  const Loading = () => (
    <div className="container my-5 py-2">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Skeleton height={400} width={400} />
        <div className="space-y-4">
          <Skeleton height={30} width={250} />
          <Skeleton height={90} />
          <Skeleton height={40} width={70} />
          <Skeleton height={50} width={110} />
          <Skeleton height={120} />
          <div className="flex space-x-4">
            <Skeleton height={40} width={110} />
            <Skeleton height={40} width={110} />
          </div>
        </div>
      </div>
    </div>
  );

  const ShowProduct = () => (
    <div className="container my-5 py-2">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <img
            className="object-cover w-full h-full rounded-md"
            src={product.image}
            alt={product.title}
            width="400"
            height="400"
          />
        </div>
        <div className="space-y-4">
          <h4 className="uppercase text-lime-500 font-semibold">
            {product.category}
          </h4>
          <h1 className="text-3xl font-bold">{product.title}</h1>
          <div className="flex items-center space-x-2">
            <p className="text-lg font-semibold">
              {product.rating && product.rating.rate}
            </p>
            <div className="text-yellow-500 space-x-1">
              {Array(4)
                .fill()
                .map((_, i) => (
                  <i key={i} className="fa fa-star"></i>
                ))}
            </div>
          </div>
          <div className="text-2xl font-bold my-4">
            ₹ {product.price}
            <span className="ml-3 text-sm bg-lime-200 text-lime-800 px-2 py-1 rounded-full">
              - {product.discount}%
            </span>
          </div>
          <p className="text-sm text-gray-600">{product.description}</p>
          <div className="flex space-x-3 mt-4">
            <button
              className="bg-lime-500 text-white px-4 py-2 rounded-md"
              onClick={() => addProduct(product)}
            >
              Add to Cart
            </button>
            <Link
              to="/cart"
              className="bg-lime-700 text-white px-4 py-2 rounded-md"
            >
              Go to Cart
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  const Loading2 = () => (
    <div className="my-4 py-4">
      <div className="flex space-x-4 overflow-auto">
        {[...Array(4)].map((_, index) => (
          <div key={index}>
            <Skeleton height={300} width={250} />
          </div>
        ))}
      </div>
    </div>
  );

  const ShowSimilarProduct = () => (
    <div className="py-4 my-4">
      <div className="flex space-x-6 overflow-auto">
        {similarProducts.map((item) => (
          <div key={item.id} className="card p-4 bg-white rounded-md shadow-md">
            <img
              className="card-img-top w-full h-60 object-cover rounded-md"
              src={item.image}
              alt={item.title}
            />
            <div className="mt-4 text-center">
              <h5 className="text-lg font-medium">
                {item.title.substring(0, 15)}...
              </h5>
            </div>
            <div className="mt-4 flex justify-center space-x-3">
              <Link
                to={`/product/${item.id}`}
                className="bg-lime-700 text-white px-4 py-2 rounded-md"
              >
                Buy Now
              </Link>
              <button
                className="bg-lime-500 text-white px-4 py-2 rounded-md"
                onClick={() => addProduct(item)}
              >
                Add to Cart
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <Navbar />
      <div className="container">
        {error ? (
          <div className="text-center text-red-500 my-4">{error}</div>
        ) : (
          <>
            <div className="row">
              {loading ? <Loading /> : product && <ShowProduct />}
            </div>
            <div className="row my-5 py-5">
              <div className="hidden md:block">
                <h2 className="text-2xl font-bold mb-4">You may also Like</h2>
                <Marquee pauseOnHover={true} pauseOnClick={true} speed={50}>
                  {loading2 ? <Loading2 /> : <ShowSimilarProduct />}
                </Marquee>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
      <Toaster />
    </>
  );
};

export default Product;
