import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Footer, Navbar } from "../components";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("auth"))?.token;

  useEffect(() => {
    if (token) {
      axios
        .post(`${BASE_URL}/verifyUser`, { token })
        .then(() => navigate("/"))
        .catch(() => localStorage.removeItem("auth"));
    }
    window.scrollTo(0, 0);
  }, [token, navigate]);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/login`, {
        email,
        password,
      });
      toast.success("Login successful");
      localStorage.setItem("auth", JSON.stringify(response.data));
      navigate("/");
    } catch (error) {
      toast.error("Failed to login");
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex justify-center items-center  bg-gray-100 px-4">
        <div className="bg-white shadow-lg rounded-lg my-[30%] md:my-[5%] p-6 sm:p-8 w-full max-w-md">
          <h1 className="text-2xl sm:text-3xl font-bold text-center text-gray-800 mb-4 sm:mb-6">
            Login
          </h1>
          <form onSubmit={handleOnSubmit} className="space-y-4 sm:space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-md sm:text-lg text-gray-600"
              >
                Email address
              </label>
              <input
                type="email"
                id="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-transparent"
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-md sm:text-lg text-gray-600"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-transparent"
              />
            </div>
            <div className="flex justify-between items-center">
              <p className="text-xs sm:text-sm text-gray-500">
                New here?{" "}
                <Link to="/register" className="text-lime-500 underline">
                  Register
                </Link>
              </p>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="w-full py-2 sm:py-3 px-4 bg-lime-500 text-white font-bold rounded-lg hover:bg-lime-600 transition duration-200"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
      <Toaster />
    </>
  );
};

export default Login;
