import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const ReturnPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Navbar />
      <div className="container mx-auto p-6">
        <h1 className="text-4xl font-bold mb-4 text-lime-600">Return Policy</h1>
        <p className="mb-6 text-gray-700">
          At Prop The Crop, customer satisfaction is our top priority. If you
          are not completely satisfied with your purchase, we're here to help.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">Returns</h2>
        <p className="mb-6 text-gray-700">
          You have 30 calendar days to return an item from the date you received
          it. To be eligible for a return, your item must be unused, in the same
          condition you received it, and in its original packaging.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">Refunds</h2>
        <p className="mb-6 text-gray-700">
          Once we receive your returned item, we will inspect it and notify you
          of the status of your refund. If your return is approved, we will
          initiate a refund to your original payment method. Please note that
          the refund process may take several business days.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">Shipping</h2>
        <p className="mb-6 text-gray-700">
          You will be responsible for paying your own shipping costs for
          returning your item. Shipping costs are non-refundable.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">
          Contact Us
        </h2>
        <p className="text-gray-700">
          If you have any questions on how to return your item, please contact
          us at{" "}
          <span className="text-lime-600 font-semibold">
            returns@propthecrop.com
          </span>
          .
        </p>
      </div>
      <Footer />
    </>
  );
};

export default ReturnPolicy;
