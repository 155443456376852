import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Navbar, Footer } from "../../components";

const Dashboard = () => {
  const navigate = useNavigate();

  const handleOnLogOut = () => {
    localStorage.removeItem("auth");
    navigate("/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              Dashboard
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Welcome to your dashboard! Here you can manage your account,
              settings, and more.
            </p>
          </div>
          <div className="flex flex-wrap -m-2">
            <Link
              to={"/user-dashboard/orders"}
              className="p-2 lg:w-1/3 md:w-1/2 w-full"
            >
              <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg cursor-pointer hover:bg-gray-100">
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">
                    Your Orders
                  </h2>
                  <p className="text-gray-500">View and manage your orders.</p>
                </div>
                <div className="ml-auto">
                  <span className="fa fa-box flex items-center justify-center text-lime-500 w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full"></span>
                </div>
              </div>
            </Link>
            <Link
              to={"/user-dashboard/credentials"}
              className="p-2 lg:w-1/3 md:w-1/2 w-full"
            >
              <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg cursor-pointer hover:bg-gray-100">
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">
                    Passwords and Security
                  </h2>
                  <p className="text-gray-500">
                    Update your password and manage security settings.
                  </p>
                </div>
                <div className="ml-auto">
                  <span className="fa fa-lock flex items-center justify-center text-lime-500 w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full"></span>
                </div>
              </div>
            </Link>
            <Link
              to={"/user-dashboard/address"}
              className="p-2 lg:w-1/3 md:w-1/2 w-full"
            >
              <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg cursor-pointer hover:bg-gray-100">
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">
                    Your Addresses
                  </h2>
                  <p className="text-gray-500">
                    Manage your shipping and billing addresses.
                  </p>
                </div>
                <div className="ml-auto">
                  <span className="fa fa-map-marker-alt flex items-center justify-center text-lime-500 w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full"></span>
                </div>
              </div>
            </Link>
            <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg cursor-pointer hover:bg-gray-100">
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">
                    Payment Options
                  </h2>
                  <p className="text-gray-500">
                    Update your payment methods and billing information.
                  </p>
                </div>
                <div className="ml-auto">
                  <span className="fa fa-credit-card flex items-center justify-center text-lime-500 w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full"></span>
                </div>
              </div>
            </div>
            <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg cursor-pointer hover:bg-gray-100">
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">
                    PropTheCrop Coins
                  </h2>
                  <p className="text-gray-500">
                    Check your balance and manage your PropTheCrop Coins{" "}
                    <span
                      className="
                      text-xs
                    "
                    >
                      [Coming Soon]
                    </span>
                    .
                  </p>
                </div>
                <div className="ml-auto">
                  <span className="fa fa-wallet flex items-center justify-center text-lime-500 w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full"></span>
                </div>
              </div>
            </div>
            <div
              className="p-2 lg:w-1/3 md:w-1/2 w-full"
              onClick={handleOnLogOut}
            >
              <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg cursor-pointer hover:bg-red-100">
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">
                    Log Out
                  </h2>
                  <p className="text-gray-500">Log Out from your Account ?</p>
                </div>
                <div className="ml-auto">
                  <span className="fa fa-sign-out-alt flex items-center justify-center text-red-500 w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Dashboard;
