import React, { useEffect, useState } from "react";
import { Footer, Navbar } from "../components";
import { Link } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/register`, {
        name,
        email,
        password,
      });

      if (response.status === 201) {
        toast.success(response.data.message);
      } else if (response.status === 409) {
        toast.error(response.data.error);
      } else {
        toast.error("Failed to register");
      }
    } catch (error) {
      toast.error("Failed to register");
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex justify-center items-center bg-gray-100 px-4">
        <div className="bg-white shadow-lg rounded-lg my-[30%] md:my-[3%] p-6 sm:p-8 w-full max-w-md">
          <h1 className="text-2xl sm:text-3xl font-bold text-center text-gray-800 mb-4 sm:mb-6">
            Register
          </h1>
          <form onSubmit={handleOnSubmit} className="space-y-4 sm:space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-md sm:text-lg text-gray-600"
              >
                Full Name
              </label>
              <input
                type="text"
                id="name"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                maxLength={15}
                className="w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-transparent"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-md sm:text-lg text-gray-600"
              >
                Email address
              </label>
              <input
                type="email"
                id="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-transparent"
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-md sm:text-lg text-gray-600"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-transparent"
              />
            </div>
            <div className="flex justify-between items-center">
              <p className="text-xs sm:text-sm text-gray-500">
                Already have an account?{" "}
                <Link to="/login" className="text-lime-500 underline">
                  Login
                </Link>
              </p>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="w-full py-2 sm:py-3 px-4 bg-lime-500 text-white font-bold rounded-lg hover:bg-lime-600 transition duration-200"
              >
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
      <Toaster />
    </>
  );
};

export default Register;
