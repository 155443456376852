import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import logo from "../components/assets/logo.png";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Navbar = () => {
  const state = useSelector((state) => state.handleCart);
  const token = JSON.parse(localStorage.getItem("auth"))?.token;
  const [user, setUser] = useState(
    localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {}
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (token) {
      axios.post(`${BASE_URL}/verifyUser`, { token }).then(
        (res) => {
          setUser(res.data);
        },
        () => {
          localStorage.removeItem("auth");
          setUser({});
        }
      );
    }
  }, [token]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-white shadow-md py-3 sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center px-4 md:px-8">
        <NavLink to="/">
          <img src={logo} alt="" className=" h-20" />
        </NavLink>
        <button
          className="md:hidden text-gray-800 focus:outline-none"
          onClick={toggleMenu}
        >
          <i className="fa fa-bars text-xl"></i>
        </button>
        <div className="hidden md:flex space-x-6">
          <NavLink className="text-gray-600 hover:text-gray-800" to="/">
            Home
          </NavLink>
          <NavLink className="text-gray-600 hover:text-gray-800" to="/product">
            Products
          </NavLink>
          <NavLink className="text-gray-600 hover:text-gray-800" to="/about">
            About
          </NavLink>
          <NavLink className="text-gray-600 hover:text-gray-800" to="/contact">
            Contact
          </NavLink>
        </div>
        <div className="hidden md:flex space-x-4">
          {Object.keys(user).length === 0 ? (
            <NavLink
              to="/login"
              className="bg-lime-600 hover:bg-lime-800 text-white px-4 py-2 rounded"
            >
              <i className="fa fa-sign-in-alt mr-1"></i> Login
            </NavLink>
          ) : (
            <NavLink
              to="/user-dashboard"
              className="bg-lime-600 hover:bg-lime-800 text-white px-4 py-2 rounded"
            >
              <i className="fa fa-dashboard mr-1"></i> {user.name.split(" ")[0]}
              's Dashboard
            </NavLink>
          )}
          <NavLink
            to="/cart"
            className="bg-lime-600 hover:bg-lime-800 text-white px-4 py-2 rounded"
          >
            <i className="fa fa-cart-shopping mr-1"></i> Cart ({state.length})
          </NavLink>
        </div>
      </div>

      <div
        className={`fixed inset-0 bg-gray-800 bg-opacity-50 z-40 transform ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 md:hidden`}
      >
        <div className="bg-white w-64 h-full shadow-md p-4">
          <button
            className="text-gray-800 focus:outline-none mb-4"
            onClick={toggleMenu}
          >
            <i className="fa fa-times text-xl"></i>
          </button>
          <nav className="space-y-4">
            <NavLink
              className="block border-t border-b py-3 text-gray-600 hover:text-gray-800"
              to="/"
              onClick={toggleMenu}
            >
              Home
              <i className="fa fa-home float-right"></i>
            </NavLink>
            <NavLink
              className="block border-t border-b py-3 text-gray-600 hover:text-gray-800"
              to="/product"
              onClick={toggleMenu}
            >
              Products
              <i className="fa fa-box float-right"></i>
            </NavLink>
            <NavLink
              className="block border-t border-b py-3 text-gray-600 hover:text-gray-800"
              to="/about"
              onClick={toggleMenu}
            >
              About
              <i className="fa fa-info-circle float-right"></i>
            </NavLink>
            <NavLink
              className="block border-t border-b py-3 text-gray-600 hover:text-gray-800"
              to="/contact"
              onClick={toggleMenu}
            >
              Contact
              <i className="fa fa-phone float-right"></i>
            </NavLink>
          </nav>
          <div className="mt-6 space-y-4">
            {Object.keys(user).length === 0 ? (
              <NavLink
                to="/login"
                className="block bg-lime-900 w-full text-center text-white px-4 py-2 rounded"
                onClick={toggleMenu}
              >
                <i className="fa fa-sign-in-alt mr-1"></i> Login
              </NavLink>
            ) : (
              <NavLink
                to="/user-dashboard"
                className="block bg-lime-900 w-full text-center text-white px-4 py-2 rounded"
                onClick={toggleMenu}
              >
                <i className="fa fa-dashboard mr-1"></i>{" "}
                {user.name.split(" ")[0]}'s Dashboard
              </NavLink>
            )}
            <NavLink
              to="/cart"
              className="block bg-lime-900 w-full text-center text-white px-4 py-2 rounded"
              onClick={toggleMenu}
            >
              <i className="fa fa-cart-shopping mr-1"></i> Cart ({state.length})
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
