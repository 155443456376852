import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const CarouselProd = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(true);
  const slideInterval = useRef(null);
  const carouselRef = useRef(null);
  const [latestArrivals, setLatestArrivals] = useState([]);

  let componentMounted = true;

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/products`);
        if (componentMounted) {
          //console.log(response.data);
          setLatestArrivals(
            response.data.sort(() => 0.5 - Math.random()).slice(0, 5)
          );
          setLoading(false);
        }
      } catch (error) {
        //console.log(error);
        setLoading(false);
      }
      return () => {
        componentMounted = false;
      };
    };

    getProducts();
  }, []);

  useEffect(() => {
    const startAutoScroll = () => {
      slideInterval.current = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % latestArrivals.length);
      }, 3000);
    };

    const stopAutoScroll = () => {
      clearInterval(slideInterval.current);
    };

    startAutoScroll();

    return () => {
      stopAutoScroll();
    };
  }, [currentSlide, latestArrivals.length]);

  const handleSwipe = (direction) => {
    if (direction === "left") {
      setCurrentSlide((prevSlide) =>
        prevSlide === latestArrivals.length - 1 ? 0 : prevSlide + 1
      );
    } else if (direction === "right") {
      setCurrentSlide((prevSlide) =>
        prevSlide === 0 ? latestArrivals.length - 1 : prevSlide - 1
      );
    }
  };

  const handleTouchStart = (e) => {
    const touchStartX = e.touches[0].clientX;
    carouselRef.current = touchStartX;
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const swipeThreshold = 50;
    const swipeDirection = carouselRef.current - touchEndX;

    if (swipeDirection > swipeThreshold) {
      handleSwipe("left");
    } else if (swipeDirection < -swipeThreshold) {
      handleSwipe("right");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-96">
        <i className="fa fa-spinner fa-spin text-4xl text-lime-500"></i>
      </div>
    );
  }

  return (
    <div
      className="relative w-11/12 overflow-hidden mx-auto py-2"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className="flex transition-transform duration-700 ease-in-out w-full"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {latestArrivals.map((item, index) => (
          <div
            key={index}
            className="w-full flex-shrink-0 flex justify-center items-center relative"
          >
            <img
              src={item.image}
              className="object-contain max-h-[60vh] max-w-full mx-auto"
              alt={item.title}
            />
            {/* Price at the bottom-right corner */}
            <div className="absolute bottom-4 right-4 bg-black bg-opacity-50 text-white px-4 py-2 rounded-lg shadow-lg">
              <span className="text-xl font-bold">&#8377;{item.price}</span>
            </div>
          </div>
        ))}
      </div>

      {/* Controls */}
      <button
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-lime-500 p-3 rounded-full text-white shadow-lg hover:bg-lime-600 transition"
        onClick={() => handleSwipe("right")}
      >
        <i className="fa fa-chevron-left md:text-2xl text-xs"></i>
      </button>
      <button
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-lime-500 p-3 rounded-full text-white shadow-lg hover:bg-lime-600 transition"
        onClick={() => handleSwipe("left")}
      >
        <i className="fa fa-chevron-right md:text-2xl text-xs"></i>
      </button>

      {/* Indicators */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {latestArrivals.map((_, index) => (
          <span
            key={index}
            className={`block h-2 w-2 rounded-full ${
              index === currentSlide ? "bg-lime-500" : "bg-gray-400"
            }`}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default CarouselProd;
