import React, { useEffect, useState } from "react";
import { Footer, Navbar } from "../components";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ContactPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("auth"))?.token;
    try {
      const response = await axios.post(
        `${BASE_URL}/contact`,
        {
          name,
          email,
          message,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.data);
    } catch (error) {
      if (error.response) {
        // Server responded with a status code out of the range of 2xx
        toast.error("Server Error: " + error.response.data);
      } else if (error.request) {
        // Request was made but no response was received
        toast.error("Network Error or CORS Issue");
      } else {
        // Something else caused the error
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <div className="container mx-auto my-[10%] md:my-[1%]  py-0 px-4 sm:py-12 sm:px-6 lg:px-8">
        <div className="flex justify-center">
          <div className="w-full max-w-md bg-white p-6 sm:p-8 rounded-lg shadow-md">
            <h1 className="text-center text-2xl sm:text-3xl font-bold mb-3 sm:mb-8">
              Contact Us
            </h1>
            <form onSubmit={handleOnSubmit} className="space-y-2 sm:space-y-6">
              <div>
                <label
                  htmlFor="Name"
                  className="block text-md sm:text-lg text-gray-700 font-semibold mb-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  className="w-full px-3 py-2 sm:px-4 sm:py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-lime-500"
                  id="Name"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="Email"
                  className="block text-md sm:text-lg text-gray-700 font-semibold mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="w-full px-3 py-2 sm:px-4 sm:py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-lime-500"
                  id="Email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="Message"
                  className="block text-md sm:text-lg text-gray-700 font-semibold mb-2"
                >
                  Message
                </label>
                <textarea
                  rows={5}
                  className="w-full px-3 py-2 sm:px-4 sm:py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-lime-500"
                  id="Message"
                  placeholder="Enter your message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </div>
              <div className="text-center">
                <button
                  className="px-4 sm:px-6 py-2 sm:py-3 bg-lime-500 text-white rounded-md hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-lime-500"
                  type="submit"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
      <Toaster />
    </>
  );
};

export default ContactPage;
