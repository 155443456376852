import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Footer, Navbar } from "../components";

const Checkout = () => {
  const state = useSelector((state) => state.handleCart || []);
  const navigate = useNavigate();

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("billingAddress"));
    if (savedData) {
      document.getElementById("firstName").value = savedData.firstName;
      document.getElementById("lastName").value = savedData.lastName;
      document.getElementById("email").value = savedData.email;
      document.getElementById("address").value = savedData.address;
      document.getElementById("address2").value = savedData.address2 || "";
      document.getElementById("country").value = savedData.country;
      document.getElementById("stateIn").value = savedData.stateIn;
      document.getElementById("zip").value = savedData.zip;
      document.getElementById("phone").value = savedData.phone;
    }
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
      email: e.target.email.value,
      address: e.target.address.value,
      address2: e.target.address2.value,
      country: e.target.country.value,
      stateIn: e.target.stateIn.value,
      zip: e.target.zip.value,
      phone: e.target.phone.value,
    };

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.address ||
      !formData.country ||
      !formData.stateIn ||
      !formData.zip ||
      !formData.phone
    ) {
      toast.error("Please fill all the required fields.");
      return;
    }

    localStorage.setItem("billingAddress", JSON.stringify(formData));
    navigate("/payment-gateway");
  };

  const EmptyCart = () => (
    <div className="container text-center py-5">
      <h4 className="mb-4 text-2xl">Your cart is empty</h4>
      <Link
        to="/"
        className="bg-lime-500 text-white px-4 py-2 rounded-md transition hover:bg-lime-600"
      >
        Continue Shopping
      </Link>
    </div>
  );

  const ShowCheckout = () => {
    const subtotal = state.reduce(
      (acc, item) => acc + item.price * item.qty,
      0
    );
    const totalItems = state.reduce((acc, item) => acc + item.qty, 0);
    const shipping = 30.0;

    return (
      <div className="container mx-auto py-5">
        <div className="flex flex-col lg:flex-row lg:space-x-10">
          <div className="lg:w-1/3">
            <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-4">
              <h5 className="text-xl font-bold mb-3">Order Summary</h5>
              <ul className="space-y-2">
                <li className="flex justify-between">
                  <span>Products ({totalItems})</span>
                  <span>₹{Math.round(subtotal)}</span>
                </li>
                <li className="flex justify-between">
                  <span>Shipping</span>
                  <span>₹{shipping}</span>
                </li>
                <li className="flex justify-between font-bold">
                  <span>Total</span>
                  <span>₹{Math.round(subtotal + shipping)}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="lg:w-2/3">
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <h4 className="text-xl font-bold mb-3">Billing Address</h4>
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      required
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    placeholder="you@example.com"
                    required
                  />
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address
                  </label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    placeholder="1234 Main St"
                    required
                  />
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="address2"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address 2 <span className="text-gray-400">(Optional)</span>
                  </label>
                  <input
                    type="text"
                    id="address2"
                    name="address2"
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    placeholder="Apartment or suite"
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                  <div>
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Country
                    </label>
                    <select
                      id="country"
                      name="country"
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      required
                    >
                      <option value="">Choose...</option>
                      <option>India</option>
                    </select>
                  </div>

                  <div>
                    <label
                      htmlFor="stateIn"
                      className="block text-sm font-medium text-gray-700"
                    >
                      State
                    </label>
                    <select
                      id="stateIn"
                      name="stateIn"
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      required
                    >
                      <option value="">Choose...</option>
                      <option>Punjab</option>
                    </select>
                  </div>

                  <div>
                    <label
                      htmlFor="zip"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Zip
                    </label>
                    <input
                      type="text"
                      id="zip"
                      name="zip"
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      required
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <div className="flex">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                      +91
                    </span>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      className="mt-1 p-2 border border-gray-300 rounded-r-md w-full"
                      placeholder="1234567890"
                      required
                    />
                  </div>
                </div>

                <div className="flex justify-between items-center mt-6">
                  <button
                    type="submit"
                    className="bg-lime-500 text-white px-4 py-2 rounded-md transition hover:bg-lime-600"
                  >
                    Continue to checkout
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Navbar />
      {state.length ? <ShowCheckout /> : <EmptyCart />}
      <Toaster />
      <Footer />
    </>
  );
};

export default Checkout;
