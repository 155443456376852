import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white text-center py-12">
      <div className="container mx-auto px-4">
        {/* Grid layout with 4 columns */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
          {/* About Us */}
          <div>
            <h5 className="text-lg font-bold text-lime-500 uppercase mb-4">
              About Us
            </h5>
            <p className="text-gray-400 leading-relaxed">
              Whether you're looking for wholesome grains or rich spices, Prop
              The Crop is your trusted source for all things organic.
            </p>
          </div>

          {/* Follow Us */}
          <div>
            <h5 className="text-lg font-bold text-lime-500 uppercase mb-4">
              Follow Us
            </h5>
            <div className="flex justify-center space-x-4">
              <a
                href="https://facebook.com/prop_the_crop"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-lime-500 transition-colors"
              >
                <i className="fa fa-facebook text-2xl"></i>
              </a>
              <a
                href="https://instagram.com/prop_the_crop"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-lime-500 transition-colors"
              >
                <i className="fa fa-instagram text-2xl"></i>
              </a>
              <a
                href="https://twitter.com/prop_the_crop"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-lime-500 transition-colors"
              >
                <i className="fa fa-twitter text-2xl"></i>
              </a>
            </div>
          </div>

          {/* Useful Links */}
          <div>
            <h5 className="text-lg font-bold text-lime-500 uppercase mb-4">
              Useful Links
            </h5>
            <div className="flex flex-col space-y-2">
              <Link
                to="/about"
                className="text-gray-400 hover:text-lime-500 transition-colors"
              >
                About Us
              </Link>
              <Link
                to="/contact"
                className="text-gray-400 hover:text-lime-500 transition-colors"
              >
                Contact Us
              </Link>
              <Link
                to="/terms-and-conditions"
                className="text-gray-400 hover:text-lime-500 transition-colors"
              >
                Terms and Conditions
              </Link>
              <Link
                to="/privacy-policy"
                className="text-gray-400 hover:text-lime-500 transition-colors"
              >
                Privacy Policy
              </Link>
              <Link
                to="/return-policy"
                className="text-gray-400 hover:text-lime-500 transition-colors"
              >
                Return Policy
              </Link>
            </div>
          </div>

          {/* Contact Us */}
          <div>
            <h5 className="text-lg font-bold text-lime-500 uppercase mb-4">
              Contact Us
            </h5>
            <p className="text-gray-400">
              Email:{" "}
              <a
                href="mailto:operations@propthecrop.com"
                className="text-lime-500 hover:underline"
              >
                operations@propthecrop.com
              </a>
            </p>
            <p className="text-gray-400">
              Phone:
              <a
                href="tel:+919490029227"
                className="text-lime-500 hover:underline"
              >
                +91 9490029227
              </a>{" "}
            </p>
            <p className="text-gray-400">GSTIN No: 37ABACA9299F1ZA</p>
            <p className="text-gray-400">FSSAI Lic No: 20124182000313</p>
          </div>
        </div>

        {/* Company Address */}
        <div className="mb-8">
          <h5 className="text-lg font-bold text-lime-500 uppercase mb-4">
            Company Address
          </h5>
          <p className="text-gray-400 leading-relaxed">
            <i className="fa fa-map-marker mr-2 text-lime-400"></i>
            Aadra Organics Private Limited - C/O RAJESH KANUMURU, Kalaya
            Kagollu, Kagulapadu, Nellore, Buchireddypalem, Andhra Pradesh,
            India, 524305
          </p>
        </div>

        <hr className="border-gray-700 mb-4" />

        {/* Copyright */}
        <p className="text-gray-500">
          ©{" "}
          {new Date().getFullYear() === 2024
            ? new Date().getFullYear()
            : `2024-${new Date().getFullYear()}`}{" "}
          Prop The Crop. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
