import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Products = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(data);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  let componentMounted = true;

  const dispatch = useDispatch();

  const addProduct = (product) => {
    dispatch(addCart(product));
  };

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/api/products`);
        if (componentMounted) {
          console.log(response.data);
          console.log(response.data);
          setData(response.data);
          setFilter(response.data);
          setLoading(false);
          setCategories(
            response.data
              .map((item) => item.category)
              .filter((value, index, self) => self.indexOf(value) === index)
          );
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      return () => {
        componentMounted = false;
      };
    };

    getProducts();
  }, []);

  const Loading = () => {
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
      </>
    );
  };

  const filterProduct = (cat) => {
    const updatedList = data.filter((item) => item.category === cat);
    setFilter(updatedList);
  };

  const ShowProducts = () => {
    return (
      <>
        <div className="flex flex-wrap justify-center w-full">
          {filter.length !== 0 ? (
            filter.map((product) => {
              return (
                <div
                  id={product.id}
                  key={product.id}
                  className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4"
                >
                  <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden">
                    <img
                      className="w-full h-56 object-cover"
                      src={product.image}
                      alt={product.title}
                    />
                    <div className="p-4">
                      <p className="text-lime-500 text-xs uppercase font-semibold tracking-wide">
                        {product.category}
                      </p>
                      <h5 className="text-lime-900 text-xl font-semibold mt-2">
                        {product.title.substring(0, 15)}...
                      </h5>
                      <p className="text-lime-700 text-sm mt-2">
                        {product.description.substring(0, 50)}...
                      </p>
                      <div className="flex items-center justify-between mt-4">
                        <p className="text-lime-900 font-semibold text-lg">
                          ₹ {product.price}
                        </p>
                        <span className="bg-lime-200 text-lime-900 px-3 py-1 rounded-full text-xs">
                          -{product.discount}% off
                        </span>
                      </div>
                      <div className="flex items-center justify-center mt-4 space-x-4">
                        <Link
                          to={"/product/" + product.id}
                          className="bg-lime-600 hover:bg-lime-700 text-white px-2 py-2 rounded-lg transition-colors shadow-lg"
                        >
                          Buy Now
                        </Link>
                        <button
                          className="bg-lime-600 hover:bg-lime-700 text-white px-2 py-2 rounded-lg transition-colors shadow-lg"
                          onClick={() => addProduct(product)}
                        >
                          Add to Cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="container my-3 py-3">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 py-5 bg-light text-center">
                    <h4 className="p-3 display-5">
                      No Product Found With This Category.
                    </h4>
                    <button
                      onClick={() => setFilter(data)}
                      className="btn btn-outline-dark mx-4"
                    >
                      <i className="fa fa-arrow-left"></i> Explore More Products
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="container my-3 py-3">
        <div className="row">
          <div className="col-12">
            <h2 className="text-4xl font-bold">Latest Products</h2>
            <hr className="my-4" />
          </div>
        </div>
        <div className="flex space-x-4 mb-20 overflow-x-scroll w-full">
          {filter.length !== 0 ? (
            filter.slice(0, 10).map((product) => {
              return (
                <div id={product.id} key={product.id} className="min-w-[250px]">
                  <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden">
                    <img
                      className="w-full h-56 object-cover"
                      src={product.image}
                      alt={product.title}
                    />
                    <div className="p-4">
                      <p className="text-lime-500 text-xs uppercase font-semibold tracking-wide">
                        {product.category}
                      </p>
                      <h5 className="text-lime-900 text-xl font-semibold mt-2">
                        {product.title.substring(0, 15)}...
                      </h5>
                      <p className="text-lime-700 text-sm mt-2">
                        {product.description.substring(0, 50)}...
                      </p>
                      <div className="flex items-center justify-between mt-4">
                        <p className="text-lime-900 font-semibold text-lg">
                          ₹ {product.price}
                        </p>
                        <span className="bg-lime-200 text-lime-900 px-3 py-1 rounded-full text-xs">
                          -{product.discount}% off
                        </span>
                      </div>
                      <div className="flex items-center justify-center mt-4 space-x-4">
                        <Link
                          to={"/product/" + product.id}
                          className="bg-lime-600 hover:bg-lime-700 text-white px-2 py-2 rounded-lg transition-colors shadow-lg"
                        >
                          Buy Now
                        </Link>
                        <button
                          className="bg-lime-600 hover:bg-lime-700 text-white px-2 py-2 rounded-lg transition-colors shadow-lg"
                          onClick={() => addProduct(product)}
                        >
                          Add to Cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="container my-3 py-3">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 py-5 bg-light text-center">
                    <h4 className="p-3 display-5">
                      No Product Found With This Category.
                    </h4>
                    <button
                      onClick={() => setFilter(data)}
                      className="btn btn-outline-dark mx-4"
                    >
                      <i className="fa fa-arrow-left"></i> Explore More Products
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Categories here */}
        <div className="row">
          <div className="col-12">
            <h2 className="text-4xl font-bold">Categories</h2>
            <hr className="my-4" />
          </div>
        </div>
        <div className="buttons text-center py-5">
          <button
            className="btn border-2 border-lime-300 hover:bg-lime-300 hover:text-lime-900 rounded-full w-fit  md:w-[8%] md:px-0 px-4 btn-sm m-2"
            onClick={() => setFilter(data)}
          >
            All
          </button>
          {categories.map((cat, index) => (
            <button
              key={index}
              className="btn border-2 border-lime-300 hover:bg-lime-300 hover:text-lime-900 rounded-full w-fit  md:w-[8%] md:px-0 px-4 btn-sm m-2"
              onClick={() => filterProduct(cat)}
            >
              {cat}
            </button>
          ))}
        </div>

        <div className="row">
          <div className="col-12">
            <h2 className="text-4xl font-bold">Products</h2>
            <hr className="my-4" />
          </div>
        </div>
        <div className="row justify-center">
          {loading ? <Loading /> : <ShowProducts />}
        </div>
      </div>
    </>
  );
};

export default Products;
