const cart = JSON.parse(localStorage.getItem("cart")) || [];

const handleCart = (state = cart, action) => {
  const product = action.payload;
  let newState;
  switch (action.type) {
    case "ADDITEM":
      // Check if product already in cart
      const exist = state.find((x) => x.id === product.id);
      if (exist) {
        // Increase the quantity
        newState = state.map((x) =>
          x.id === product.id ? { ...x, qty: x.qty + 1 } : x
        );
      } else {
        newState = [...state, { ...product, qty: 1 }];
      }
      break;
    case "DELITEM":
      const exist2 = state.find((x) => x.id === product.id);
      if (exist2.qty === 1) {
        newState = state.filter((x) => x.id !== exist2.id);
      } else {
        newState = state.map((x) =>
          x.id === product.id ? { ...x, qty: x.qty - 1 } : x
        );
      }
      break;
    default:
      newState = state;
      break;
  }

  // Save new state to local storage
  localStorage.setItem("cart", JSON.stringify(newState));
  return newState;
};

export default handleCart;
